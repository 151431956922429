<template>
  <component :is="'paragraph_' + data.subtype" />
</template>
<script>
export default {
  components: {
    paragraph_a() {
      return import("./paragraph_a");
    },
    paragraph_p() {
      return import("./paragraph_p");
    }
  },
  props: {},
  data() {
    return {
      data: JSON.parse(
        JSON.stringify(
          this.$store.state.business.unsaved.form[this.$store.state.form.edit]
        )
      )
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
